import { LenderWithInterestRates } from './car-details';

export enum BookingTypes {
    SEE = 'SEE',
    RESERVE = 'RESERVE',
}

export enum ReserveTypes {
    HOME = 'HOME',
    SHOWROOM = 'SHOWROOM',
}
export enum BookingButtonTypes {
    Payments = 'payments',
    Call = 'call',
    Directions = 'directions',
}
export interface BookingStep {
    id: number;
    title: string;
    subtitle: string;
    subtitleWhenDone: string;
    completed: boolean;
    previousBtnText: string;
    nextBtnText: string;
}
export interface DateTimeSlot {
    timeSlotId: string;
    from: string;
    to: string;
    totalCapacityPerSlot: number;
    sameCarSlot: boolean;
    isAvailable: boolean;
    numberOfBookings: number;
}
export interface Toaster {
    flag: boolean;
    message: string;
    type: boolean;
}

export interface ClientValidationData {
    name: string;
    email?: string;
    phone: string | number;
    otp0: number;
    otp1: number;
    otp2: number;
    otp3: number;
    otp4: number;
    otp5: number;
}

export interface IGetVehicleDetailsByBookingId {
    id: string;
    make: string;
    model: string;
    year: number;
    kilometers: string;
    transmission: string;
    sylndr_selling_price: string;
    images: string[];
    discount_price: number;
    installment_price: string;
}

export interface IGetVehicleDetailsByBookingIdResult {
    data: IGetVehicleDetailsByBookingId[];
    message: string;
    status: number;
}

export interface IBookedVehicleDetails {
    id: string;
    make: string;
    model: string;
    year: number;
    kilometers: string;
    transmission: string;
    sylndr_selling_price: string;
    images: string[];
    discount_price: number;
    installment_price: string;
    lenders: string[];
    registration_fees: number | null;
}
export interface IBookedVehicleDetailsResult {
    data: IBookedVehicleDetails[];
    statusCode: number;
    message: string;
}

export interface IBookedCarDetails {
    id: string;
    make: string;
    model: string;
    year: number;
    kilometers: string;
    transmission: string;
    sylndrSellingPrice: string;
    images: string[];
    discountPrice: number;
    installmentPrice: string;
    lenders: LenderWithInterestRates[];
}

export enum AllowedVehicleBookingTypes {
    SEE_ONLY = 'SEE_ONLY',
    RESERVE_ONLY = 'RESERVE_ONLY',
    SEE_RESERVE = 'SEE_RESERVE',
}

export enum CustomerBookingStatusEnum {
    CREATED = 'CREATED2',
    ACTIVE = 'ACTIVE',
    SUCCESSFUL = 'SUCCESSFUL',
    SCHEDULED = 'SCHEDULED',
    RESCHEDULED = 'RESCHEDULED',
    CANCELLED = 'CANCELLED',
}

export enum RetailBookingStatus {
    Scheduled = 'SCHEDULED',
    Rescheduled = 'RESCHEDULED',
    Canceled = 'CANCELED',
    NoShow = 'NO_SHOW',
    Show = 'SHOW',
    ClosedSuccess = 'CLOSED_SUCCESS',
    ClosedFailure = 'CLOSED_FAILURE',
}
export enum CardStatusTypes {
    PURCHASING = 'PURCHASING',
    CANCELED = 'CANCELLED',
    SCHEDULED = 'SCHEDULED',
    PURCHASED = 'PURCHASED',
    RESCHEDULED = 'RESCHEDULED',
    RETURNED = 'RETURNED',
    SUCCESSFUL = 'SUCCESSFUL',
}

export enum PaymentsHeaderStatusTypes {
    INPROGRESS = 'INPROGRESS',
    REFUNDING = 'REFUNDING',
}
export enum PaymentsLogsStatusTypes {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    NEEDS_YOUR_ATTENTION = 'NEEDS_YOUR_ATTENTION',
    REFUNDED = 'REFUNDED',
    REFUNDING = 'REFUNDING',
}
export enum PaymentsLogsPaymentsTypes {
    INSTAPAY = 'INSTAPAY',
    BANK_TRANSFERS = 'BANK_TRANSFERS',
    BANK_CHEQUE = 'BANK_CHEQUE',
    RESERVATION_FEES = 'RESERVATION_FEES',
}
export enum SylndrBanksOptions {
    CIB = 'CIB',
    BANK_MISR = 'BANK_MISR',
    NBE = 'NBE',
    EG_BANK = 'EG_BANK',
}
export enum SylndrFinancialOptions {
    SYLNDR = 'Sylndr',
    PROJECT = 'Project',
}
